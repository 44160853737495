import { useCallback, useEffect, useMemo, useState } from 'react'
import algoliaSearchInsights from 'search-insights'
import { LineItem, ProductSummary } from 'shared-types'
import { BaseMoney } from 'shared-types/src/commercetools'
import { FilterMapProps } from '~/components/FiltersDrawer'
import { useSession } from '../useSession'

const ALGOLIA_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_INDEX
const defaultFraction = 2
const dollarsToCentsDenominator = 100
const ALGOLIA_STORAGE_KEY = 'algolia--queryid'

export const initAlgoliaClient = () => {
  algoliaSearchInsights('init', {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  })
}

export const useAlgoliaSearchInsignts = () => {
  const { session } = useSession()
  const [latestQueryId, setLatestQueryId] = useState('')

  const loadQueryIDsFromStorage = useCallback(() => {
    const queryId = localStorage.getItem(ALGOLIA_STORAGE_KEY)
    setLatestQueryId(queryId || '')
  }, [setLatestQueryId])

  const updateLatestQueryId = useCallback(
    (queryId: string) => {
      if (latestQueryId !== queryId) {
        setLatestQueryId(queryId)
        localStorage.setItem(ALGOLIA_STORAGE_KEY, queryId)
      }
    },
    [latestQueryId, setLatestQueryId]
  )

  const userId = useMemo(() => {
    if (session?.token?.isLoggedIn) {
      return session?.token?.customerId
        ? `user-${session.token.customerId}`
        : undefined
    }
    return session?.token?.anonymousId
      ? `anonymous-${session.token.anonymousId}`
      : undefined
  }, [session])

  const priceValue = (price: BaseMoney) => {
    return price?.centAmount
      ? (price.centAmount / dollarsToCentsDenominator).toFixed(defaultFraction)
      : '0.00'
  }

  const buildProductEventData = useCallback(
    (products: ProductSummary[]) => {
      const filtered = products?.filter((p) => {
        return !!p.listId
      })

      if (!userId || !filtered?.length) {
        return null
      }

      return {
        userToken: userId,
        index: ALGOLIA_INDEX,
        objectIDs: filtered.map((p) => {
          return p.listId
        }),
      }
    },
    [userId]
  )

  const buildPurchaseEventData = useCallback(
    (lineItems: LineItem[], totalPrice: BaseMoney, queryId?: string) => {
      if (!userId || !lineItems?.length) {
        return null
      }

      const productIds = []
      let value = 0
      const productData = lineItems.map((p) => {
        const price = priceValue(p.price.value)

        productIds.push(p.productId)
        value += Number(price)

        return {
          queryID: p.queryId || queryId,
          price,
          discount: priceValue(p.price.discounted?.value),
          quantity: p.quantity,
        }
      })

      return {
        userToken: userId,
        index: ALGOLIA_INDEX,
        objectIDs: productIds,
        objectData: productData,
        currency: totalPrice.currencyCode,
        value,
      }
    },
    [userId]
  )

  const sendClickEvent = useCallback(
    (products: ProductSummary[], positions?: number[], queryID = '') => {
      const data = buildProductEventData(products)
      if (!data) {
        return
      }

      if (positions?.length && queryID) {
        algoliaSearchInsights('clickedObjectIDsAfterSearch', {
          queryID,
          positions,
          eventName: 'prod_click_after_search',
          ...data,
        })
      }

      queryID.length === 0 &&
        algoliaSearchInsights('clickedObjectIDs', {
          eventName: 'prod_click',
          ...data,
        })
    },
    [buildProductEventData]
  )

  const sendViewProductsEvent = useCallback(
    (products: ProductSummary[]) => {
      const data = buildProductEventData(products)

      if (!data) {
        return
      }

      algoliaSearchInsights('viewedObjectIDs', {
        eventName: 'prod_view',
        ...data,
      })
    },
    [buildProductEventData]
  )

  const sendConversionEvent = useCallback(
    (products: ProductSummary[], queryID = '') => {
      const data = buildProductEventData(products)

      if (!data) {
        return
      }

      if (queryID) {
        algoliaSearchInsights('convertedObjectIDsAfterSearch', {
          eventName: 'prod_converted',
          queryID,
          ...data,
        })
        return
      }

      algoliaSearchInsights('convertedObjectIDs', {
        eventName: 'prod_converted',
        ...data,
      })
    },
    [buildProductEventData]
  )

  const sendAddToCartEvent = useCallback(
    (lineItems: LineItem[], totalPrice: BaseMoney, queryID = '') => {
      const data = buildPurchaseEventData(lineItems, totalPrice)

      if (!data) {
        return
      }

      if (queryID) {
        algoliaSearchInsights('addedToCartObjectIDsAfterSearch', {
          eventName: 'prod_add_to_cart_after_search',
          queryID,
          ...data,
        })
        return
      }

      algoliaSearchInsights('addedToCartObjectIDs', {
        eventName: 'prod_add_to_cart',
        ...data,
      })
    },
    [buildPurchaseEventData]
  )

  const sendPurchaseEvent = useCallback(
    (lineItems: LineItem[], totalPrice: BaseMoney, queryId?: string) => {
      const data = buildPurchaseEventData(lineItems, totalPrice, queryId)

      if (!data) {
        return
      }

      const hasQueryId = data.objectData.some((o) => {
        return !!o.queryID
      })

      if (hasQueryId) {
        algoliaSearchInsights('purchasedObjectIDsAfterSearch', {
          eventName: 'prod_purchased_after_search',
          ...data,
        })
        return
      }

      algoliaSearchInsights('purchasedObjectIDs', {
        eventName: 'prod_purchased',
        ...data,
      })
    },
    [buildPurchaseEventData]
  )

  const sendFilterEvent = useCallback(
    (filterMap?: FilterMapProps, isConverted = false) => {
      if (!userId || !filterMap) {
        return
      }

      const filters = Object.keys(filterMap).map((mkey: string) => {
        const mvalue = !filterMap[mkey]?.includes('')
          ? filterMap[mkey].join(',')
          : 'Search All Products'

        return `${mkey}:${mvalue}`
      })

      if (!filters.length) {
        return
      }

      algoliaSearchInsights(isConverted ? 'clickedFilters' : 'viewedFilters', {
        userToken: userId,
        index: ALGOLIA_INDEX,
        eventName: isConverted ? 'prod_clicked_filter' : 'prod_viewed_filters',
        filters,
      })
    },
    [userId]
  )

  useEffect(() => {
    loadQueryIDsFromStorage()
  }, [loadQueryIDsFromStorage])

  return {
    latestQueryId,
    initAlgoliaClient,
    sendClickEvent,
    sendViewProductsEvent,
    sendConversionEvent,
    sendAddToCartEvent,
    sendPurchaseEvent,
    sendFilterEvent,
    updateLatestQueryId,
  }
}
