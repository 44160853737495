import dayjs from 'dayjs'
import { JWT, Session } from 'next-auth'
import { Result, adaptResult, errResult } from 'shared-types'

import { ApiError } from '../helpers/apiError'
import { ApiRequestOptions } from '../types/ApiRequestOptions'
import { ApiResult } from '../types/ApiResult'
import { CancellablePromise } from '../types/CancellablePromise'
import { Client } from './client'
import { HTTPStatus } from './httpStatus'

const getResponseBody = async (response: Response) => {
  try {
    return await response.json()
  } catch (err) {
    return null
  }
}

const getQuery = (options: ApiRequestOptions): string => {
  const { query } = options
  if (typeof query === 'string') {
    return `?${query}`
  }
  if (typeof query === 'object') {
    return `?${new URLSearchParams(query as Record<string, string>).toString()}`
  }
  return ''
}

const cacheError = (options: ApiRequestOptions, result: ApiResult) => {
  const errors: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
  }

  const error = errors[result.status]
  if (error) {
    throw new ApiError(options, result, error)
  }
  if (!result.ok) {
    throw new ApiError(options, result, error)
  }
}

const fetchRequest = async ({ url, query, method, headers, body, signal }) => {
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}${url}${query}`, {
    method,
    headers,
    body: JSON.stringify(body),
    signal,
  })
}

export const request = async <T>(
  options: ApiRequestOptions,
  session?: Session
): Promise<Result<T, ApiError>> => {
  return new CancellablePromise(async (resolve, _reject, cancel) => {
    try {
      const { url, headers = {}, body, method } = options
      const query = getQuery(options)
      const authHeaders: Record<string, string> = {}
      authHeaders['Content-Type'] = 'application/json'
      if (session?.token) {
        authHeaders.authorization = `Bearer ${session.token.accessToken}`
      }
      if (session?.token?.storeKey) {
        authHeaders.storeKey = session?.token?.storeKey
      }
      if (session?.token?.deliveryPostcode) {
        authHeaders['delivery-postcode'] = session.token.deliveryPostcode
      }
      if (session?.token?.deliveryCity) {
        authHeaders['delivery-city'] = session.token.deliveryCity
      }
      const reqHeaders = {
        ...authHeaders,
        ...headers,
      }
      const abortController = new AbortController()
      const _abortController = new AbortController()

      const _refreshToken = async (
        refreshToken: string
      ): Promise<Result<JWT, Error>> => {
        if (!refreshToken) {
          return Client.auth.accessToken()
        }
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API_BASE_URL}/bff/auth/refreshToken/${refreshToken}`
        )
        const json = await response.json()
        if (response.status === HTTPStatus.Ok) {
          return adaptResult(json)
        }
        return errResult(Error('Error generating refresh token'))
      }

      let response: Response
      response = await fetchRequest({
        url,
        query,
        method,
        headers: reqHeaders,
        body,
        signal: abortController.signal,
      })
      const now = dayjs()
      const expiryDate = dayjs(session?.token?.expiryDate)
      if (
        response.status === HTTPStatus.Unauthorized ||
        (session?.token?.isLoggedIn &&
          response.status === HTTPStatus.InternalServerError)
      ) {
        const shouldLogout =
          !expiryDate.isBefore(now) ||
          response.status === HTTPStatus.InternalServerError
        const sessionResponse = await _refreshToken(
          shouldLogout ? undefined : session?.token?.refreshToken
        )
        await session.updateSession({
          ...session,
          token: {
            ...sessionResponse.getValue(),
          },
        })

        if (sessionResponse.getValue()) {
          authHeaders.authorization = `Bearer ${
            sessionResponse.getValue().accessToken
          }`
        }
        const newReqHeaders = {
          ...reqHeaders,
          ...authHeaders,
        }
        response = await fetchRequest({
          url,
          query,
          method,
          headers: newReqHeaders,
          body,
          signal: _abortController.signal,
        })
      }

      const responseBody = await getResponseBody(response)

      const apiResult: ApiResult<T> = {
        ok: response.ok,
        status: response.status,
        url,
        body: responseBody,
      }

      cancel(() => {
        abortController.abort()
        _abortController.abort()
      })

      cacheError(options, apiResult)
      resolve(adaptResult(apiResult.body))
    } catch (err) {
      resolve(errResult(err))
    }
  })
}
