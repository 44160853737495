import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useCookie } from 'react-use'
import { Coordinates, GoogleMapsGeolocateResult } from 'shared-types'
import { CookieKeys } from '~/constants/cookieKeys'
import { UserLocationContextProps } from './UserLocationProvider.types'

export const UserLocationContext = createContext<UserLocationContextProps>({
  isGeolocating: false,
  lastGeolocation: undefined,
})

const geolocateByGoogleApi = async (): Promise<Coordinates> => {
  const response = await fetch(
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`,
    { method: 'POST' }
  )
  if (!response.ok) {
    throw new Error('Unable to geolocate by Google API')
  }
  const json: GoogleMapsGeolocateResult = await response.json()
  if (json.location) {
    return {
      latitude: json.location?.lat,
      longitude: json.location?.lng,
    }
  }
  throw new Error('Unable to geolocate by Google API')
}

const geolocateByBrowser = async (): Promise<Coordinates> => {
  if (!navigator.geolocation) {
    throw new Error('Browser geolocation not supported')
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position.coords) {
          resolve(position.coords)
        } else {
          reject()
        }
      },
      () => {
        reject()
      }
    )
  })
}

const parseGeolocationCookie = (value?: string): Coordinates | undefined => {
  if (!value) {
    return
  }
  try {
    const [latitude, longitude] = value.split(',')
    return {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    }
  } catch {}
}

const composeGeolocationCookie = (location: Coordinates): string => {
  return `${location.latitude},${location.longitude}`
}

export const UserLocationProvider = ({ children }: PropsWithChildren) => {
  const [isGeolocating, setIsGeolocating] = useState(false)

  const [lastGeolocationCookieValue, setLastGeolocationCookieValue] = useCookie(
    CookieKeys.geolocation
  )

  const lastGeolocation = useMemo<Coordinates | undefined>(() => {
    return parseGeolocationCookie(lastGeolocationCookieValue)
  }, [lastGeolocationCookieValue])

  const setLastGeolocation = useCallback(
    (location: Coordinates) => {
      if (!location) {
        return
      }
      setLastGeolocationCookieValue(composeGeolocationCookie(location))
    },
    [setLastGeolocationCookieValue]
  )

  const geolocate = useCallback(
    async (
      eager = false,
      skipBrowser = false
    ): Promise<Coordinates | undefined> => {
      let location: Coordinates

      // Return last geolocation if eager and available
      if (eager && lastGeolocation) {
        return lastGeolocation
      }

      setIsGeolocating(true)

      if (!skipBrowser) {
        try {
          location = await geolocateByBrowser()
        } catch (err) {
          console.warn(err)
        }
      }

      if (!location) {
        try {
          location = await geolocateByGoogleApi()
        } catch (err) {
          console.warn(err)
        }
      }

      setLastGeolocation(location)
      setIsGeolocating(false)
      return location
    },
    [lastGeolocation, setLastGeolocation]
  )

  const geolocateWithPermission = useCallback(async (): Promise<
    Coordinates | undefined
  > => {
    let location: Coordinates
    try {
      location = await geolocateByBrowser()
    } catch (err) {
      console.warn(err)
    }

    return location
  }, [])

  const result = useMemo(() => {
    return {
      isGeolocating,
      lastGeolocation,
      geolocate,
      geolocateWithPermission,
    }
  }, [isGeolocating, lastGeolocation, geolocate, geolocateWithPermission])

  return (
    <UserLocationContext.Provider value={result}>
      {children}
    </UserLocationContext.Provider>
  )
}
