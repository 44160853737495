import * as gtag from '~/lib/gtag'
import { formatPriceForDataLayer } from '../gtm/helpers'

export const handleLoadEvent = (pageProps, gtagId, router) => {
  if (pageProps?.page.head?.pageType === 'home') {
    return gtag.pageview({
      action: 'page_view',
      payload: {
        send_to: gtagId,
        ecomm_pagetype: 'home',
      },
    })
  }
  if (router.asPath?.includes('/search')) {
    return gtag.pageview({
      action: 'page_view',
      payload: {
        send_to: gtagId,
        ecomm_pagetype: 'searchresults',
      },
    })
  }
  if (pageProps?.page.head?.pageType === 'category') {
    return gtag.pageview({
      action: 'page_view',
      payload: {
        send_to: gtagId,
        ecomm_pagetype: 'category',
      },
    })
  }
  if (pageProps?.page.head?.pageType === 'product') {
    return gtag.pageview({
      action: 'page_view',
      payload: {
        send_to: gtagId,
        ecomm_prodid: pageProps?.page.head.product.sku,
        ecomm_pagetype: 'product',
        ecomm_totalvalue: formatPriceForDataLayer(
          pageProps?.page.head.product.offer.price
        ),
      },
    })
  }
  if (pageProps?.page.head?.pageType === 'purchase') {
    return null
  }
  return gtag.pageview({
    action: 'page_view',
    payload: {
      send_to: gtagId,
      ecomm_pagetype: 'other',
    },
  })
}
