import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Session } from 'next-auth'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Coordinates } from 'shared-types'
import { useCustomer, useToast } from '~/hooks'
import { Client } from '~/customClients/client'
import {
  UserDeliveryMethods,
  UserDeliveryPostcodeProviderContext,
} from './UserDeliveryPostcodeProvider.types'

const PostcodeConfigureDrawer = dynamic(
  async () => {
    const mod = await import('~/components/PostcodeConfigureDrawer')
    return mod.PostcodeConfigureDrawer
  },
  { ssr: false }
)

export const UserDeliveryPostcodeContext =
  createContext<UserDeliveryPostcodeProviderContext>(null)

const fetchNearestStore = async (coordinates, session: Session, sku) => {
  const stores = await Client.store.getNearestStore(
    coordinates,
    session,
    1,
    sku
  )
  return stores.getValue()?.[0]
}

const PREFERRED_STORE_CACHE_KEY = 'preferred-store'

export const UserDeliveryPostcodeProvider = ({
  children,
}: PropsWithChildren) => {
  const router = useRouter()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [isAuthDrawerOpen, setIsAuthDrawerOpen] = useState(false)
  const [deliveryMethods, setDeliveryMethods] =
    useState<UserDeliveryMethods[]>()
  const { customer } = useCustomer()
  const { data: session, update } = useSession()
  const queryClient = useQueryClient()
  const [sku, setSku] = useState('')

  const userStoreKey = session?.token?.storeKey || customer?.preferredStoreKey
  const hasOpened = useRef(false)
  hasOpened.current = hasOpened.current || drawerIsOpen || !!userStoreKey
  const [isDefaultStoreSet, setIsDefaultStoreSet] = useState(false)

  const setStoreByStoreKey = useCallback(
    async (storeKey) => {
      await update({
        ...session,
        token: {
          ...session.token,
          storeKey,
          sku,
        },
      })
    },
    [session, update, sku]
  )

  const setPreferredStoreByLatLong = useCallback(
    async (location: Coordinates) => {
      const store = await fetchNearestStore(location, session, sku)
      if (store) {
        queryClient.setQueryData(
          [PREFERRED_STORE_CACHE_KEY, store.storeKey],
          store
        )
        await setStoreByStoreKey(store.storeKey)
        if (!isDefaultStoreSet) {
          await router.replace(router.asPath)
          setIsDefaultStoreSet(true)
        }
      }
    },
    [isDefaultStoreSet, queryClient, router, session, setStoreByStoreKey, sku]
  )

  const postcode =
    session?.token?.deliveryPostcode || customer?.deliveryPreviewPostcode

  const city = session?.token?.deliveryCity || customer?.deliveryPreviewCity

  const setPostcodeAndCity = useCallback(
    async (postcode: string, city: string) => {
      await update({
        ...session,
        token: {
          ...session.token,
          deliveryPostcode: postcode,
          deliveryCity: city,
        },
      })
    },
    [session, update]
  )

  const { showNotification, closeNotification } = useToast()

  const setUserPostcode = useCallback(
    async (postcode: string, city: string) => {
      if (!postcode || !city) {
        return
      }
      await setPostcodeAndCity(postcode, city)
      if (router.asPath === '/store-finder') {
        setDrawerIsOpen(false)
        return
      }

      if (isDefaultStoreSet) {
        await router.replace(router.asPath)
      }
    },
    [isDefaultStoreSet, router, setPostcodeAndCity, setDrawerIsOpen]
  )
  const handleDrawerOpen = useCallback(() => {
    setDrawerIsOpen(true)
  }, [setDrawerIsOpen])

  const handleDrawerClose = useCallback(() => {
    setDrawerIsOpen(false)
  }, [setDrawerIsOpen])

  const setDeliveryPreviewPostcode = useCallback(
    async (postcode: string, city: string) => {
      try {
        await setUserPostcode(postcode, city)
        showNotification({
          children: (
            <span>
              You&apos;ve selected{' '}
              <span className='font-extrabold'>{postcode}</span> as your
              postcode
            </span>
          ),
          autoClose: true,
          id: 'postcode-success',
          type: 'default',
          onClose: closeNotification,
        })
      } catch (e) {
        showNotification({
          children: `Oops! Something went wrong while setting your postcode`,
          autoClose: true,
          id: 'postcode-error',
          type: 'error',
          onClose: closeNotification,
        })
      }
    },
    [closeNotification, setUserPostcode, showNotification]
  )

  const getAvilableShippingCodes = useCallback(async () => {
    if (!postcode) {
      return setDeliveryMethods([])
    }
    const response = await Client.store.getDeliverToPostcodeAvailabilityStatus(
      session,
      postcode
    )
    const deliveryMethods = response.getValueSafe()?.deliveryMethods
    return setDeliveryMethods(deliveryMethods as UserDeliveryMethods[])
  }, [postcode, session])

  useEffect(() => {
    getAvilableShippingCodes()
  }, [getAvilableShippingCodes])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleDrawerClose)
    return () => {
      router.events.off('routeChangeComplete', handleDrawerClose)
    }
  }, [router, handleDrawerClose])

  const value = useMemo<UserDeliveryPostcodeProviderContext>(() => {
    return {
      postcode,
      city,
      promptUserPostcodeDrawer: handleDrawerOpen,
      setDeliveryPreviewPostcode,
      deliveryMethods,
      sku,
      setSku,
      setPreferredStoreByLatLong,
      setStoreByStoreKey,
      isAuthDrawerOpen,
      setIsAuthDrawerOpen,
      postCodeDrawerIsOpen: drawerIsOpen,
    }
  }, [
    city,
    deliveryMethods,
    drawerIsOpen,
    handleDrawerOpen,
    isAuthDrawerOpen,
    postcode,
    setDeliveryPreviewPostcode,
    setPreferredStoreByLatLong,
    setStoreByStoreKey,
    sku,
  ])

  return (
    <UserDeliveryPostcodeContext.Provider value={value}>
      {children}

      {hasOpened.current && (
        <PostcodeConfigureDrawer
          open={drawerIsOpen}
          onClose={handleDrawerClose}
          onChangePostcode={setDeliveryPreviewPostcode}
          editOnly={false}
          postcode={postcode}
          city={city}
          deliveryMethods={deliveryMethods}
          setPreferredStoreByLatLong={setPreferredStoreByLatLong}
        />
      )}
    </UserDeliveryPostcodeContext.Provider>
  )
}
