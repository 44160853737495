import { formatPriceForDataLayer } from '~/lib/gtm/helpers'

const createItemObject = (product, index) => {
  return {
    item_id: product?.sku || '',
    item_name: product?.title || '',
    price:
      Number(formatPriceForDataLayer(product?.finalPrice?.centAmount)) || 0,
    item_brand: product?.brandName || '',
    item_category: product?.category1Name || '',
    item_category2: product?.category2Name || '',
    item_category3: product?.category3Name || '',
    item_category4: product?.category4Name || '',
    item_category5: product?.category5Name || '',
    item_list_id: product?.listId || '',
    item_list_name: product?.listName || '',
    index,
    item_status_tags: product?.item_status_tags || '',
  }
}

export const adaptPromotionImpression = ({
  promotionId,
  promotionName,
  creativeName,
  creativeSlot,
  product,
  index = 0,
}) => {
  return {
    promotion_id: promotionId || '',
    promotion_name: promotionName || '',
    creative_name: creativeName || '',
    creative_slot: creativeSlot || '',
    items: product ? [createItemObject(product, index)] : [],
  }
}
