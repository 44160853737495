import { Session } from 'next-auth'
import { CustomerList, Result } from 'shared-types'
import { ApiError } from '~/helpers/apiError'
import { IListsClient } from '~/types/Client'
import { ListDetailsProps } from '~/sections/ListDetails'
import { request } from './request'

export class ListsClient implements IListsClient {
  getLists = async (
    productIds: string[],
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/list?productIds=${productIds?.join(',')}`,
        method: 'GET',
      },
      session
    )
  }

  getListDetail = async (
    listId: string,
    session: Session
  ): Promise<Result<ListDetailsProps, ApiError>> => {
    return request(
      {
        url: `/bff/lists/list/${listId}/details`,
        method: 'GET',
      },
      session
    )
  }

  addToList = async (
    listId: string,
    productIds: string[],
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/list/${listId}`,
        method: 'POST',
        body: {
          productIds,
        },
      },
      session
    )
  }

  removeToList = async (
    listId: string,
    productIds: string[],
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/list/${listId}/products?productIds=${productIds.join(
          ','
        )}`,
        method: 'DELETE',
      },
      session
    )
  }

  deleteList = async (
    listId: string,
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/list/${listId}`,
        method: 'DELETE',
      },
      session
    )
  }

  renameList = async (
    listId: string,
    name: string,
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/list/${listId}`,
        method: 'PUT',
        body: {
          name,
        },
      },
      session
    )
  }

  duplicateList = async (
    listId: string,
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/list/${listId}/duplicate`,
        method: 'POST',
      },
      session
    )
  }

  createList = async (
    name: string,
    productIds: string[],
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/list`,
        method: 'POST',
        body: {
          name,
          productIds,
        },
      },
      session
    )
  }

  addToCart = async (
    listId: string,
    session: Session
  ): Promise<Result<unknown, ApiError>> => {
    return request(
      {
        url: `/bff/lists/list/${listId}/add-to-cart`,
        method: 'PATCH',
      },
      session
    )
  }

  getProjects = async (
    productIds: string[],
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/projects?productIds=${productIds.join(',')}`,
        method: 'GET',
      },
      session
    )
  }

  addToProject = async (
    listId: string,
    productIds: string[],
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/projects/${listId}`,
        method: 'POST',
        body: {
          productIds,
        },
      },
      session
    )
  }

  updateProjectBookmark = async (
    name,
    projectId,
    status,
    session: Session
  ): Promise<Result<CustomerList, ApiError>> => {
    return request(
      {
        url: `/bff/bookmark-project`,
        method: 'POST',
        body: {
          name,
          projectId,
          status,
        },
      },
      session
    )
  }

  createProject = async (
    name: string,
    productIds: string[],
    session: Session
  ): Promise<Result<CustomerList[], ApiError>> => {
    return request(
      {
        url: `/bff/lists/projects`,
        method: 'POST',
        body: {
          name,
          productIds,
        },
      },
      session
    )
  }

  updateList = async (
    listId: string,
    session: Session,
    productId: string,
    quantity: number
  ): Promise<Result<ListDetailsProps, ApiError>> => {
    return request(
      {
        url: `/bff/lists/list/${listId}/products?productIds=${productId}&count=${quantity}`,
        method: 'PATCH',
      },
      session
    )
  }
}
