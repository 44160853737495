import { PropsWithChildren, createContext, useMemo, useState } from 'react'
import { CartDrawerProviderProps } from './CartDrawerProvider.types'

export const CartDrawerProviderContext = createContext<CartDrawerProviderProps>(
  {}
)

export const CartDrawerProvider = ({ children }: PropsWithChildren) => {
  const [isCartOpen, setIsCartOpen] = useState(false)

  const closeCartDrawer = () => {
    return setIsCartOpen(false)
  }
  const openCartDrawer = () => {
    return setIsCartOpen(true)
  }

  const value = useMemo(() => {
    return {
      isCartOpen,
      closeCartDrawer,
      openCartDrawer,
    }
  }, [isCartOpen])

  return (
    <CartDrawerProviderContext.Provider value={value}>
      {children}
    </CartDrawerProviderContext.Provider>
  )
}
