export const getImageSrc = (src: string, width: string) => {
  if (!src) {
    return null
  }

  if (
    src.includes('/_next/static') ||
    !src.includes('https://') ||
    src.includes('data:')
  ) {
    return src
  }

  const source = new URL(src)
  source.searchParams.append('width', width)
  source.searchParams.append('format', 'webp')
  return source.toString()
}

export const getImageSrcSet = (
  src: string,
  srcSets: { width: string; intrinsicImageSize: string }[]
) => {
  if (!src || !srcSets) {
    return null
  }

  return srcSets
    .map(({ width, intrinsicImageSize }) => {
      return `${getImageSrc(src, width)} ${intrinsicImageSize}w`
    })
    .join(',')
}
