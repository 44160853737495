import { Session } from 'next-auth'
import { UpdateCartRequest, Cart } from '~/types'

import { ICartClient } from '../types/Client'
import { request } from './request'

export class CartClient implements ICartClient {
  addToCart = async (
    payload: UpdateCartRequest[],
    session: Session
  ): Promise<Cart> => {
    const response = await request<Cart>(
      {
        url: `/bff/cart/add`,
        method: 'POST',
        body: payload,
      },
      session
    )

    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }

  public updateCart = async (
    payload: UpdateCartRequest[],
    session: Session
  ): Promise<Cart> => {
    const response = await request<Cart>(
      {
        url: `/bff/cart/update`,
        method: 'PATCH',
        body: payload,
      },
      session
    )
    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }

  public getCart = async (session: Session): Promise<Cart> => {
    if (!session) {
      return null
    }

    const response = await request<Cart>(
      {
        url: '/bff/cart/details',
        method: 'GET',
      },
      session
    )

    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }
}
